import { ACT_SYNC_CLIENT_GUEST_INFO } from "./types";
import {
  SET_CLIENT_PERMISSION_MENU,
  SET_CLIENT_MENU_HEADER,
} from "@/store/modules/AuthClient/types";
import authClientService from "@/services/client/auth/login";
import store from "@/store";

export default {
  [ACT_SYNC_CLIENT_GUEST_INFO]: async () => {
    try {
      let response = await authClientService.getInfo();
      let { permission_menu, header_menu } = response;
      store.commit(
        `AuthClient/${SET_CLIENT_PERMISSION_MENU}`,
        permission_menu || []
      );
      store.commit(`AuthClient/${SET_CLIENT_MENU_HEADER}`, header_menu || []);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

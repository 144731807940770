import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "recommendations",
  name: "RecommendationManage",
  component: () =>
    import(
      /* webpackChunkName: "recommendation-manage-layout-view" */ "@/views/Manage/Recommendation/LayoutView.vue"
    ),
  redirect: { name: "RecommendationEdit" },
  children: [
    {
      path: "",
      name: "RecommendationEdit",
      component: () =>
        import(
          /* webpackChunkName: "recommendation-manage-edit-view" */ "@/views/Manage/Recommendation/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};

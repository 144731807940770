import "@babel/polyfill";
import "mutationobserver-shim";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import i18n from "./plugins/i18n";
import alertSystem from "@/plugins/alert-system";
import eventBus from "@/plugins/event-bus";
import filters from "@/plugins/filters";
import VModal from "vue-js-modal";
import "vue-js-modal/dist/styles.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "animate.css";
import "select2";
import JQuery from "jquery";
import VueMask from "v-mask";
import VCalendar from "v-calendar";
import Validation from "@/plugins/validation";
import PortalVue from "portal-vue";
import Toast from "vue-toastification";
import { SET_INSTANCE_SEARCHING, GET_PATH_SEARCH } from "@/store/types";
import "vue-toastification/dist/index.css";
import "./plugins/bootstrap-vue";
// import managePermissionView from '@/plugins/management-permission';
import globalState from "@/plugins/globalState";
import moment from "moment";
import { CachingComponent } from "./plugins/cache";
import VueSocketIO from "vue-socket.io";
import { socket } from "./socket";
// import setupInterceptor from '@/plugins/api_service/setupInterceptor';
import "@/directives";
import "@ckeditor/ckeditor5-build-classic/build/translations/ja.js";
// import "ckeditor5/ckeditor5.css";
import BootstrapMain from "./bootstrap-main";
Vue.config.productionTip = false;

// --------- PLUGINS ---------
// setupInterceptor(store, router);
Vue.use(BootstrapMain);
window.$ = JQuery;
Vue.prototype.$ = JQuery;

Vue.use(eventBus);
Vue.use(alertSystem);
Vue.use(VModal);
Vue.use(filters);
Vue.use(VueMask);
Vue.use(VCalendar);
Vue.use(Validation);
Vue.use(PortalVue);
Vue.use(Toast, {
  timeout: 2000,
});
Vue.use(CachingComponent);

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socket,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
// Vue.use(managePermissionView);
Vue.use(globalState);
// ----- GLOBAL ------

// scroll top window
Vue.prototype.scrollTopWindow = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// handle history route
window.setSearchingRoute = function (routeName = null, path = null) {
  store.commit(SET_INSTANCE_SEARCHING, { routeName, path });
};

window.goBackSearchingRoute = function (routeName) {
  return store.getters[GET_PATH_SEARCH](routeName);
};

// **** be careful with this when build for PRODUCTION, please changing to FALSE before build ENV PRODUCTION ****
// const envBuilding = process.env.VUE_APP_BUILDING;
//  envBuilding === "production" ? false :
Vue.config.devtools = true;
// ********************************************************
Vue.prototype.$domain = "." + process.env.VUE_APP_DOMAIN;
Vue.prototype.$moment = moment;

const maxUploadFileSizeMB = process.env.VUE_APP_MAX_UPLOAD_FILE_SIZE_MB || 5;
const maxUploadFileSizeGB = process.env.VUE_APP_MAX_UPLOAD_FILE_SIZE_GB || 5;
Vue.prototype.$maxUploadFileSize = {
  mb: maxUploadFileSizeMB,
  byte: maxUploadFileSizeMB * 1024 ** 2,
  gb: {
    size: maxUploadFileSizeGB * 1024 ** 3,
    text: `${maxUploadFileSizeGB}gb`,
  },
};
let controllerCancel = {};

const app = new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
});

app.$mount("#app");

export { controllerCancel };

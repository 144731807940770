import DashboardLayout from "@/layouts/TheDashboardLayout";
import accountRoute from "./account";
import organizationRoute from "./organization";
import memberRoute from "./member";
import mailTemplateRoute from "./mail-template";
import mailTemplateSettingRoute from "./mail-template-setting";
import freeMailTemplateRoute from "./free-mail-template";
import eventRoute from "./event";
import depositRoute from "./deposit";
import invoiceRoute from "./invoice";
import contentRoute from "./content";
import paymentPlanRoute from "./payment-plan";
import paymentPlanSubscriberRoute from "./payment-plan-subscriber";
import customizePageRoute from "./customize-page";
import eventParticipant from "./event-participant";
import settingRoute from "./setting";
import masterCategoryCustomizationRoute from "./master-category-customization";
import paymentAdjustmentRoute from "./payment-adjustment";
import roleRoute from "./role";
import onboardingRoute from "./onboarding";
import corporateRoute from "./corporate";
import accountPermission from "./account-permission";
import functionalMenuRoute from "./functional-menu";
import billingAddressRoute from "./billing_address";
import accountSetting from "./account-setting";
import staffRoute from "./staff";
import accessTemplate from "./access-template";
import accessContent from "./access-content";
import contentSubscriber from "./content-subscriber";
import recommendation from "./recommendation";

import { auth as authManage } from "@/middlewares/manage";
// import { GET_VALID_DOMAIN_FACILITY,  ACT_CHECK_VALID_DOMAIN_FACILITY} from '@/store/modules/facility/types';
import selectOrganization from "@/middlewares/manage/select-organization";
import { checkAuthManageLifeTime } from "@/helpers/authenticate";
import hostRoute from "@/router/modules/manage/host";
import { GET_FACILITY_INFO } from "@/store/modules/Facility/types";
import Store from "@/store";

export default [
  {
    path: "/manage",
    component: () => import("@/layouts/TheContent"),
    beforeEnter: (to, from, next) => {
      if (
        process.env.VUE_APP_ADMIN_URL &&
        window.location.host == process.env.VUE_APP_ADMIN_URL &&
        /^\/manage/.test(to.path)
      ) {
        next({ name: "EdicOverView" });
      } else {
        if (to.path == "/manage" || to.path == "/manage/")
          next({ name: "manageOverview" });
        else next();
      }
    },
    // beforeEnter: async (to, from, next) => {
    //   if (to.matched.some((res) => res.meta.requiredDomain)) {
    //     let isValidDomain = await Store.getters[`Facility/${GET_VALID_DOMAIN_FACILITY}`];
    //     if (!isValidDomain) {
    //       let hostname = location.hostname.split('.'); // example: facility.local.env => ['facility', 'local', 'env']

    //       await Store.dispatch(`Facility/${ACT_CHECK_VALID_DOMAIN_FACILITY}`, { url: hostname[0] });
    //       isValidDomain = await Store.getters[`Facility/${GET_VALID_DOMAIN_FACILITY}`];

    //       if (!isValidDomain) {
    //         next({ path: '404' })
    //       } else {
    //         next();
    //       }
    //     } else {
    //       return next();
    //     }
    //   }
    //   if (to.matched.some((res) => res.meta.requiredAuth)) {
    //     console.log('require auth')
    //   }
    // },

    children: [
      {
        path: "login",
        name: "ManageLogin",
        component: () =>
          import(
            /* webpackChunkName: "manage-login-view" */ "@/views/Manage/Login/LoginManageView.vue"
          ),
        beforeEnter: (to, from, next) => {
          let isAuthManage = checkAuthManageLifeTime();
          if (isAuthManage) {
            next({ name: "ManageSelectFacilities" });
          }
          next();
        },
      },
      {
        path: "forgot-password",
        name: "ManageForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "manage-forgot-password-view" */ "@/views/Manage/PasswordAssistant/ForgotPassword.vue"
          ),
      },
      {
        path: "reset-password",
        name: "ManageResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "manage-reset-password" */ "@/views/Manage/PasswordAssistant/ResetPassword.vue"
          ),
      },
      {
        path: "select-facilities",
        name: "ManageSelectFacilities",
        component: () =>
          import(
            /* webpackChunkName: "manage-select-organization-view" */ "@/views/Manage/SelectOrganization/SelectOrganizationView.vue"
          ),
        meta: {
          middleware: [authManage],
        },
      },
      {
        path: "/",
        name: "ManageHome",
        component: DashboardLayout,
        redirect: { name: "manageOverview" },
        children: [
          {
            path: "overview",
            name: "manageOverview",
            component: () => import("@/views/Manage/Overview/Layout.vue"),
            meta: {
              middleware: [authManage, selectOrganization],
            },
          },
          accountRoute,
          organizationRoute,
          memberRoute,
          mailTemplateRoute,
          mailTemplateSettingRoute,
          freeMailTemplateRoute,
          eventRoute,
          depositRoute,
          invoiceRoute,
          eventParticipant,
          contentRoute,
          paymentPlanRoute,
          paymentPlanSubscriberRoute,
          hostRoute,
          settingRoute,
          customizePageRoute,
          masterCategoryCustomizationRoute,
          paymentAdjustmentRoute,
          roleRoute,
          onboardingRoute,
          corporateRoute,
          accountPermission,
          functionalMenuRoute,
          billingAddressRoute,
          accountSetting,
          staffRoute,
          accessTemplate,
          accessContent,
          contentSubscriber,
          recommendation,
          // {
          //   path: "filter-searching",
          //   component: () => import("@/views/FilterSearching.vue"),
          //   meta: {
          //     middleware: [authManage, selectOrganization],
          //   },
          // },
        ],
      },
      {
        path: "unauthorized",
        name: "MananagePageUnauthorized",
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/401.vue"
          ),
      },
      {
        path: ":pathMatch(.*)*",
        name: "ManagePageNotFound",
        beforeEnter: (to, from, next) => {
          let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
          if (facilityInfo && facilityInfo.name) {
            document.querySelector("title").textContent = facilityInfo.name;
          }
          next();
        },
        component: () =>
          import(
            /* webpackChunkName: "page-not-found-view" */ "@/views/Errors/404.vue"
          ),
      },
    ],
  },
];

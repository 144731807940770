/* eslint-disable */

// import axios from "axios";
import ckeditorUploadService from "@/services/common/ckeditor-upload";
import UploadStorageFile from "@/services/uploadFile/uploadFileInstance";

const maxFileSize = 5242880;
export class MyUploadAdaptor {
  constructor(loader, custom_upload_config) {
    this.loader = loader;
    this.custom_upload_config = custom_upload_config;
  }
  upload() {
    const formData = new FormData();
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          formData.append("upload", file, file.name);
          if (file.size > maxFileSize) {
            return reject("5MB以下の画像を指定してください。");
          }
          return ckeditorUploadService
            .uploadFile({
              file_name: file.name,
              ...this.custom_upload_config,
            })
            .then((res) => {
              const { url, fields, file_path, cloud_storage_service } = res;
              // binding
              // Object.keys(fields).forEach((key) => {
              //   formData.append(key, fields[key]);
              // });
              // formData.append("file_name", customize_file_name);
              const instanceUploader = new UploadStorageFile({
                url,
                fields,
                file: file,
                cloud_storage_service,
              });
              instanceUploader
                .prepareUpload()
                .then(() => {
                  this.loader.uploaded = true;
                  resolve({
                    default: file_path,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  reject("OH .....");
                });
            });
        })
    );
  }
}

export default function ThisCustomUploadAdaptorPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdaptor(loader, {
      content_id: 123,
      content_cms: "CMS!@3!@#123",
      data: "!@3",
    });
  };
}

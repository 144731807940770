import {
  ACT_CHECK_VALID_DOMAIN_FACILITY,
  ACT_SET_THE_CHECK_ACCOUNT_USAGE,
  ACT_GET_FACILITY_ALERT_ACOUNT_USAGE,
} from "./types";
import adminFacilityService from "@/services/admin/facilities";
import manageFacilityService from "@/services/manage/facilities";

export default {
  async [ACT_CHECK_VALID_DOMAIN_FACILITY]({ state, dispatch }, payload) {
    let { url } = payload;

    try {
      let response = await adminFacilityService.validURL(url);
      state.facility_domain.isValidDomain = true;
      state.facility_domain.info = response;
      if (response.check_account_usage) {
        dispatch(ACT_SET_THE_CHECK_ACCOUNT_USAGE, response.check_account_usage);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  },

  [ACT_SET_THE_CHECK_ACCOUNT_USAGE]({ state }, payload) {
    state.facility_domain.show_alert_check_account_usage = true;
    Object.assign(state.facility_domain.check_account_usage, payload);
  },

  async [ACT_GET_FACILITY_ALERT_ACOUNT_USAGE]({ dispatch }) {
    let response = await manageFacilityService.checkAccountUsage();
    if (response) {
      dispatch(ACT_SET_THE_CHECK_ACCOUNT_USAGE, response);
    }
  },

  async [ACT_GET_FACILITY_ALERT_ACOUNT_USAGE]({ dispatch }) {
    return new Promise((resolve, reject) => {
      manageFacilityService
        .checkAccountUsage()
        .then((response) => {
          dispatch(ACT_SET_THE_CHECK_ACCOUNT_USAGE, response);
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  },
};

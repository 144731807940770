<template>
  <div>
    <div class="tabs__header">
      <div v-scroll-horizontal class="tabs__header__wrapper">
        <div class="tabs__header__wrapper-content">
          <div
            v-for="(tab, index) in tabs"
            :key="tab.title"
            :class="{ active: index == selectedIndex }"
            class="tab__item"
            @click="selectTab(index)"
          >
            <div v-if="index == selectedIndex" class="active__tab__top"></div>
            <p class="m-0 p-0">{{ tab.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonTabWrapper",
  props: {
    useVIf: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  watch: {
    activeTab: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.selectTab(value);
      },
    },
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    if (this.activeTab) {
      this.selectTab(this.activeTab);
    } else {
      this.selectTab(0);
    }
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

import Store from "@/store";
import Router from "@/router";
import { flag } from "@/constants/commons";
import eventBus from "@/helpers/eventBus";
import {
  ACT_FORCE_FRESH_AUTH,
  ACT_SYNC_CLIENT_AUTH_INFO,
  GET_PROCESS_INFO_IS_DONE,
} from "@/store/modules/AuthClient/types";
import { GET_FACILITY_INFO } from "@/store/modules/Facility/types";
import { ACT_SYNC_CLIENT_GUEST_INFO } from "@/store/modules/GuestClient/types";
import { checkClientManageLifeTime } from "@/helpers/authenticate";

export default async function authClient({ to, next }) {
  const isAuthClient = checkClientManageLifeTime();
  const gotoHomepageOrOther = async (facilityInfo) => {
    const allowGuestFlag =
      facilityInfo.facility_settings.system_allow_guest_flag;
    if (allowGuestFlag == flag.use.value) {
      await Store.dispatch(`GuestClient/${ACT_SYNC_CLIENT_GUEST_INFO}`);
      return next();
    } else {
      let { path } = to;
      setTimeout(() => {
        return Router.push({
          name: "ClientJbbfLoginView",
          query: { redirect: path },
        });
      }, 500);
    }
  };
  // not authenticated
  if (!isAuthClient) {
    let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
    if (facilityInfo) {
      return gotoHomepageOrOther(facilityInfo);
    }
    eventBus.$on("got-facility-info", gotoHomepageOrOther);
  } else {
    const isProcessInfoDone =
      Store.getters[`AuthClient/${GET_PROCESS_INFO_IS_DONE}`];
    if (!isProcessInfoDone) {
      try {
        await Store.dispatch(`AuthClient/${ACT_SYNC_CLIENT_AUTH_INFO}`);
      } catch {
        Store.dispatch(`AuthClient/${ACT_FORCE_FRESH_AUTH}`);
        return next({ name: "ClientJbbfLoginView" });
      }
    }
    if (to.name === "ClientJbbfLoginView") {
      return next({ name: "ClientHomeView" });
    }
    return next();
  }
}

import {
  facilityUsingAppStatus,
  facilityStatus,
  invoiceStatus,
  invoicePaidStatus,
  invoiceSendStatus,
  genderType,
  corporatePrincipleFlag,
} from "@/constants/commons";
import { formatMoney } from "@/helpers/common";
import moment from "moment";
// this config to hidden waring: "moment.js:287  Deprecation warning: value provided is not in a recognized RFC2822 or ISO format"
moment.suppressDeprecationWarnings = true;

const findValueInConstant = (constantObjcect, valueInput) => {
  let textResult = "";
  for (let prop in constantObjcect) {
    if (constantObjcect[prop].value === parseInt(valueInput)) {
      textResult = constantObjcect[prop].text;
      return textResult;
    }
  }
  return "";
};

export default {
  install(Vue) {
    Vue.filter("ftFacilityAppStatus", function (valueInput) {
      return findValueInConstant(facilityUsingAppStatus, valueInput);
    });

    Vue.filter("ftFacilityStatus", function (valueInput) {
      return findValueInConstant(facilityStatus, valueInput);
    });

    Vue.filter("formatDateTime", function (valueInput, $string = "-") {
      if (valueInput) {
        return moment(valueInput).format(
          "YYYY" + $string + "MM" + $string + "DD HH:mm"
        );
      }
    });
    Vue.filter("formatDate", function (valueInput, $string = "-") {
      if (valueInput) {
        return moment(valueInput).format(
          "YYYY" + $string + "MM" + $string + "DD"
        );
      }
    });
    Vue.filter("currency", function (valueInput, currencyType = "") {
      if (valueInput) {
        return (
          currencyType +
          valueInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } else {
        return 0;
      }
    });

    Vue.filter(
      "currency_with_subFix",
      function (valueInput, currencyType = "", subFix = "") {
        if (valueInput) {
          return (
            currencyType +
            valueInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " " +
            subFix
          );
        } else {
          return 0;
        }
      }
    );

    Vue.filter("formatCurrency", function (valueInput) {
      return formatMoney(valueInput);
    });

    Vue.filter("invoiceStatus", function (valueInput) {
      return findValueInConstant(invoiceStatus, valueInput);
    });

    Vue.filter("invoicePaidStatus", function (valueInput) {
      return findValueInConstant(invoicePaidStatus, valueInput);
    });

    Vue.filter("invoiceSendStatus", function (valueInput) {
      return findValueInConstant(invoiceSendStatus, valueInput);
    });

    Vue.filter("genderType", function (valueInput) {
      return findValueInConstant(genderType, valueInput);
    });

    Vue.filter("staffCorporatePrincipleFlag", function (valueInput) {
      return findValueInConstant(corporatePrincipleFlag, valueInput);
    });
  },
};

<template>
  <div v-if="displayContent" class="d-block">
    <template v-if="useVIf">
      <div v-if="isActive" class="tab__item__content__wrapper">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <div v-show="isActive" class="tab__item__content__wrapper">
        <slot></slot>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "CommonTabItem",
  props: {
    title: {
      type: String,
      default: "Tab",
    },
    useVIf: {
      type: Boolean,
      default: true,
    },
    displayContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isActive() {
      if (this.isActive) {
        this.triggerTab();
      }
    },
  },
  methods: {
    triggerTab() {
      this.$emit("active-tab");
    },
  },
};
</script>

<style lang="scss" scoped></style>

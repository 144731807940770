import { ref, computed, onBeforeMount } from "vue";
import Store from "@/store";
import {
  GET_VALID_DOMAIN_FACILITY,
  ACT_CHECK_VALID_DOMAIN_FACILITY,
  GET_FACILITY_INFO,
} from "@/store/modules/Facility/types";
import i18n from "@/plugins/i18n";
import eventBus from "@/helpers/eventBus";
import cloneDeep from "lodash/cloneDeep";

export default function useCheckingValidDomain() {
  const loading = ref(false);
  const error = ref(false);
  const isValidDomain = computed(
    () => Store.getters[`Facility/${GET_VALID_DOMAIN_FACILITY}`]
  );

  const checkValidDomain = async () => {
    if (!isValidDomain.value) {
      loading.value = true;
      // false
      try {
        await Store.dispatch(`Facility/${ACT_CHECK_VALID_DOMAIN_FACILITY}`, {
          url: location.hostname,
        });
        if (!isValidDomain.value) {
          error.value = true;
          return;
        }
        loading.value = false;
        let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
        // set title browser and logo image
        document.querySelector("title").textContent = facilityInfo.name;
        document.querySelector("link[rel*='icon']").href =
          facilityInfo.logo_image_path ? facilityInfo.logo_image_path : "";
        setLanguage(facilityInfo);
        eventBus.$emit("got-facility-info", facilityInfo);
      } catch (err) {
        console.log(err);
        loading.value = false;
        error.value = true;
      }
    } else {
      let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
      document.querySelector("title").textContent = facilityInfo.name;
      setLanguage(facilityInfo);
      eventBus.$emit("got-facility-info", facilityInfo);
    }
  };

  const checkIsEdicAdminRoute = () => {
    // if (
    //   (process.env.VUE_APP_ADMIN_URL &&
    //     window.location.host == process.env.VUE_APP_ADMIN_URL) ||
    //   /^localhost/.test(window.location.host)
    // )
    return (
      (process.env.VUE_APP_ADMIN_URL &&
        window.location.host == process.env.VUE_APP_ADMIN_URL) ||
      /^localhost/.test(window.location.host)
    );
  };

  const setLanguage = (facilityInfo) => {
    const routePath = window.location.pathname;
    // If is not client page
    if (/^\/manage/.test(routePath)) {
      return false;
    }
    const lang = "ja";
    if (facilityInfo?.language?.[lang]) {
      const langs = getFacilityLanguage(facilityInfo.language[lang]);
      i18n.mergeLocaleMessage(lang, langs);
    }
  };

  const getFacilityLanguage = (langs) => {
    let tmpLangs = cloneDeep(langs);
    for (let item in tmpLangs) {
      for (let itemLang in tmpLangs[item]) {
        if (tmpLangs[item][itemLang] == "") {
          delete tmpLangs[item][itemLang];
        }

        if (tmpLangs[item][itemLang] == "-") {
          tmpLangs[item][itemLang] = "";
        }
      }
    }
    return tmpLangs;
  };

  onBeforeMount(async () => {
    if (checkIsEdicAdminRoute()) {
      return;
    } else {
      await checkValidDomain();
    }
  });

  return {
    loading,
    error,
  };
}

import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "app-setting",
  name: "EdicAdminFacilityAppSettingManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-app-setting-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/AppSetting/Layout"
    ),
  redirect: { name: "EdicAdminFacilityAppSetting" },
  children: [
    {
      path: "",
      name: "EdicAdminFacilityAppSetting",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-app-setting" */ "@/views/EdicAdmin/Facilities/Manage/AppSetting/AppSetting"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};

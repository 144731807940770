import axios from "axios";
import reqConstant from "@/constants/request";
import { buildUrl, parseAccountCorporateCode } from "@/helpers/common.js";
import StatusCode from "@/constants/statusCode";
import Request from "@/constants/request";
import store from "@/store";
import { GET_AUTH_TOKEN } from "@/store/modules/AuthManage/types";
import { GET_AUTH_TOKEN as GET_ADMIN_AUTH_TOKEN } from "@/store/modules/AuthAdmin/types";
import {
  GET_CLIENT_AUTH_TOKEN,
  GET_CLIENT_ACCOUNT_CODE,
  GET_CLIENT_LOGIN_ORGANIZATION,
  ACT_FORCE_FRESH_AUTH as ACT_FORCE_FRESH_AUTH_CLIENT,
} from "@/store/modules/AuthClient/types";
import { ACT_FORCE_FRESH_AUTH } from "@/store/modules/AuthManage/types";
import {
  GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE,
  GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE,
  GET_FACILITY_CODE,
} from "@/store/modules/Facility/types";
import { GET_ID_ORGANIZATION_NEXT_LOGIN } from "@/store/modules/Organization/types";
import { GET_FACILITY_MENU_ID } from "@/store/modules/ManagePermissionScreen/types";
import { controllerCancel } from "@/main";
import CancelTokenInstance from "@/models/system/CancelTokenInstance";
import Store from "@/store";
import router from "@/router";

import { ApplicationClientCorporateCode } from "../setting";
// function to call refresh token
function refreshToken() {
  return axios({
    method: "post",
    url: "/api/auth/refresh-token",
    headers: {
      Authorization: "Bearer " + store.getters["Auth/getToken"],
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

let refreshing_token = null;
class CommonService {
  constructor(baseURL, role) {
    this.role = role;

    const options = {
      baseURL: baseURL,
      timeout: reqConstant.defaultRequestTimeOut,
      headers: {
        accept: reqConstant.accept,
        "content-type": reqConstant.contentType,
      },
    };

    this.axiosDownloadInstance = axios.create({
      ...options,
      responseType: "blob",
    });

    this.axiosInstance = new axios.create(options);
    this.axiosInstance.defaults.withCredentials = true;

    // interceptor request
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        let token = "";
        switch (this.role) {
          case "Member":
            token = await store.getters[`AuthClient/${GET_CLIENT_AUTH_TOKEN}`];
            break;
          case "Manage":
            token = await store.getters[`AuthManage/${GET_AUTH_TOKEN}`];
            break;
          case "Admin": //todo change
            token = await store.getters[`AuthAdmin/${GET_ADMIN_AUTH_TOKEN}`];
            break;
        }
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        // add header facility_code
        let facility_code = store.getters[`Facility/${GET_FACILITY_CODE}`];
        if (facility_code) {
          config.headers["facility-code"] = facility_code;
        }
        // add header account-code  - client jbbf
        let accountCode =
          store.getters[`AuthClient/${GET_CLIENT_ACCOUNT_CODE}`];
        if (accountCode) {
          config.headers["account-code"] = accountCode;
        }
        // add header login-organization for management api
        if (this.role == "Manage") {
          let login_organization =
            store.getters[`Organization/${GET_ID_ORGANIZATION_NEXT_LOGIN}`];
          if (login_organization) {
            config.headers["login-organization"] = login_organization;
          }
        }
        // add header login-organization for member api
        if (this.role == "Member") {
          let login_organization =
            store.getters[`AuthClient/${GET_CLIENT_LOGIN_ORGANIZATION}`];
          if (login_organization) {
            config.headers["login-organization"] = login_organization;
          }
        }
        // add header facility-menu-id
        let facilityMenuId =
          store.getters[`ManagePermissionScreen/${GET_FACILITY_MENU_ID}`] ||
          store.getters[`ClientPermissionScreen/${GET_FACILITY_MENU_ID}`];
        if (facilityMenuId) {
          config.headers["facility-menu-id"] = facilityMenuId;
        }
        // add header use_organization_structure
        let use_organization_structure =
          store.getters[
            `Facility/${GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE}`
          ];
        if (use_organization_structure) {
          config.headers["use-organization-structure"] =
            use_organization_structure;
        }
        // add header use_corporation_structure
        let use_corporation_structure =
          store.getters[
            `Facility/${GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE}`
          ];
        if (use_corporation_structure) {
          config.headers["use-corporate-structure"] = use_corporation_structure;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // interceptor response
    this.axiosInstance.interceptors.response.use(
      (res) => {
        return Promise.resolve(res);
      },
      async (err) => {
        if (!axios.isCancel(err)) {
          const originalConfig = err.config;

          if (originalConfig.url !== "/login" && err.response) {
            // check status code from backend [401]
            if (err.response.status === 401) {
              if (err.response.data.error === "TokenInValid") {
                location.replace("/login");
                return false;
              } else if (
                err.response.data.error === "TokenHasExpired" &&
                !originalConfig._retry
              ) {
                originalConfig._retry = true;
                try {
                  refreshing_token = refreshing_token
                    ? refreshing_token
                    : refreshToken();
                  // call refresh token
                  let res = await refreshing_token;
                  refreshing_token = null;
                  let { expires_in, access_token } = res.data.data;
                  store.dispatch("Auth/refreshToken", {
                    access_token,
                    expires_in,
                  });
                  return this.axiosInstance(originalConfig);
                } catch (err) {
                  // token has been black list
                  if (err.response.status === 403) {
                    location.replace("/auth/login/teacher_miss_token");
                    return;
                  }
                  return Promise.reject(err);
                }
              } else {
                switch (this.role) {
                  case "Member":
                    Store.dispatch(`AuthClient/${ACT_FORCE_FRESH_AUTH_CLIENT}`);
                    router
                      .replace({ name: "ClientJbbfLoginView" })
                      .catch(() => {});
                    break;
                  case "Manage":
                    // 1. refresh state auth manage
                    store.dispatch(`AuthManage/${ACT_FORCE_FRESH_AUTH}`);
                    // window.location.replace('/manage/login')
                    break;
                  case "Admin": //todo change
                    // token = await store.getters[`AuthManage/${GET_AUTH_TOKEN}`];
                    break;
                }
              }
            }
          }
          return Promise.reject(err);
        } else {
          return Promise.reject({ isCancel: true });
        }
      }
    );
  }

  getOptions(header = {}) {
    var options = {
      headers: buildHeaders(header),
    };
    return options;
  }

  // Handle error
  handleError(err) {
    if (err.response) {
      let statusCodeResponse = err.response.status; // get status from err
      let structureError = {};
      structureError.statusCode = statusCodeResponse || "";
      // handle error code
      if (err.response.data?.error_code) {
        structureError.error_code = err.response.data?.error_code;
      }

      // 422 validation
      if (statusCodeResponse === StatusCode.HTTP_UNPROCESSABLE_ENTITY) {
        structureError.failValidation = true;
        structureError.data = parseValidationMessage(err.response.data.error);
        structureError.source_data = err.response.data.error;
        return structureError;
      }
      // 403 forbidden
      if (statusCodeResponse === StatusCode.HTTP_FORBIDDEN) {
        structureError.forbidden = true;
        structureError.data =
          err.response.data.message || "Your action is not allowed!";
        return structureError;
      }

      // 401 Unauthorize
      if (statusCodeResponse === StatusCode.HTTP_UNAUTHORIZED) {
        // token WRONG
        structureError.unauthorized = true;
        structureError.data = err.response.data.message || "Unauthorize";
        structureError.error_data = err.response.data.error;
        // refresh store auth to default null and clear all localstorage
        // Store.dispatch('Auth/refreshAuth');

        // if (window.location.pathname !== '/auth/login') {
        //     location.reload();
        // }

        // try {
        //     Store.dispatch('Auth/login', { login_id: 'admin@admin.com', password: '123'})
        //     .then(res =>console.log(res));

        // } catch (err) {

        // }
        return structureError;
      }

      // 404 Not Found ==> when Id-edit is not found or not belongs to
      if (statusCodeResponse === StatusCode.HTTP_NOT_FOUND) {
        structureError.notFound = true;
        return structureError;
      }

      // 400 Bad request
      if (statusCodeResponse === StatusCode.HTTP_BAD_REQUEST) {
        structureError.badRequest = true;
        structureError.data = err.response.data.message;
        return structureError;
      }

      //402 Payment required (member account is temp -> need payment to become account official)
      if (
        statusCodeResponse === StatusCode.HTTP_PAYMENT_REQUIRED &&
        this.role == "Member"
      ) {
        // get corporate first before get account_code
        let corporateCode = localStorage.getItem(
          ApplicationClientCorporateCode
        );
        let accountCode =
          store.getters[`AuthClient/${GET_CLIENT_ACCOUNT_CODE}`];
        const invoiceCode = err.response.data.error.invoice_code;
        const accountCorporateCode = parseAccountCorporateCode(
          accountCode,
          corporateCode
        );
        if (!invoiceCode || !accountCorporateCode) {
          router.replace({ name: "ClientJbbfLoginView" }).catch(() => {});
          return true;
        }
        setTimeout(() => {
          router.replace({
            name: "ClientNewEntryConfirmPaymentView",
            params: {
              code: btoa(accountCorporateCode),
              invoice_code: btoa(invoiceCode),
            },
          }); //.catch(()=>{window.location.reload()})
        }, 500);
      }

      structureError.data = "Unknow error!";
      return structureError;
    }

    return err;
  }

  // Handle success
  handleSuccess(response) {
    // if (response.status === StatusCode.HTTP_OK) {
    //     let message = response.data.message || 'Successfull';
    //     Store.dispatch('setAlertSystem', {
    //         show: true,
    //         error: false,
    //         message: message
    //     })
    // }
    //   console.log(response.data);
    //   console.log(Object.prototype.hasOwnProperty.call(response.data, 'account_menu_permission'))
    if (
      Object.prototype.hasOwnProperty.call(
        response.data,
        "account_menu_permission"
      ) &&
      response.data.account_menu_permission
    ) {
      if (!response.data.data) {
        response.data.data = {};
      }
      response.data.data.account_menu_permission =
        response.data.account_menu_permission;
    }
    return response.data.data || response.data;
  }

  get(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);

    let { useCancelToken = true } = query;

    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };
    //   if (cancelToken) {
    //     options.cancelToken = cancelToken;
    //   }
    let cancelTokenInstance = null;
    if (useCancelToken && url) {
      // init
      cancelTokenInstance = new CancelTokenInstance(url, query, null, options);
      if (cancelTokenInstance.isValidInstance()) {
        options.cancelToken = cancelTokenInstance.getCancelToken();
        controllerCancel[cancelTokenInstance.instanceNameUrl()] =
          cancelTokenInstance;
      }
    }

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, options)
        .then((res) => {
          if (
            cancelTokenInstance &&
            typeof cancelTokenInstance == "object" &&
            cancelTokenInstance.constructor.name == CancelTokenInstance.name
          ) {
            if (
              Object.prototype.hasOwnProperty.call(
                controllerCancel,
                cancelTokenInstance.instanceNameUrl()
              )
            ) {
              delete controllerCancel[cancelTokenInstance.instanceNameUrl()];
            }
          }
          resolve(this.handleSuccess(res));
        })
        .catch((err) => {
          if (!err.isCancel) {
            reject(this.handleError(err));
          }
        });
    });
  }

  retrieveFileUrl(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };
    //   url = buildUrl(url, query);

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  post(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);

    //   url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, body, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  put(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);
    //   url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, body, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  delete(url, query = {}, headers = {}) {
    let options = this.getOptions(headers);
    // url = buildUrl(url, query);
    options = {
      ...options,
      withCredentials: true,
      params: { ...query },
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(url, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  postFile(
    url,
    query = {},
    file,
    body = {},
    headers = {},
    onUploadProgress = null
  ) {
    let formData = new FormData();
    formData.append("file-upload", file);

    for (let key in body) {
      formData.append(key, body[key]);
    }

    let options = this.getOptions(headers);
    if (onUploadProgress) {
      options.onUploadProgress = onUploadProgress;
    }

    url = buildUrl(url, query);

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, formData, options)
        .then((res) => resolve(this.handleSuccess(res)))
        .catch((err) => reject(this.handleError(err)));
    });
  }

  downloadFile(url, query = {}, body = {}, headers = {}) {
    let options = this.getOptions(headers);
    // url = buildUrl(url, query);
    options = {
      ...options,
      params: { ...query },
      responseType: "blob",
    };

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, body, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(this.handleError(err)));
    });
  }
}

// function building header
function buildHeaders(headers) {
  let requestHeaders = getDefaultRequestHeaders();

  if (!headers) {
    return requestHeaders;
  }
  if (Array.isArray(headers)) {
    return requestHeaders;
  }
  for (let keyname in headers) {
    requestHeaders[keyname.toLowerCase()] = headers[keyname];
  }

  return requestHeaders;
}

// default request headers
function getDefaultRequestHeaders() {
  return {
    page: 1,
    timeout: Request.defaultRequestTimeOut,
    "content-type": "application/json",
    // "Authorization": "Bearer " + StorageManage.getStorage(ApplicationStorageToken),
  };
}

// parse Validation Message from laravel
export function parseValidationMessage(listMessage) {
  if (typeof listMessage != "object") {
    throw new Error("Parameter type should be a object!");
  }
  let validation = {};
  for (let key in listMessage) {
    validation[key] = listMessage[key][0];
  }
  return validation;
}

export default CommonService;

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  config: null,
  facility_domain: {
    isValidDomain: false,
    info: null,
    check_account_usage: {
      usage_limited_flag: false,
      usage_limited_message: "",
      usage_alert_flag: false,
      usage_alert_message: "",
    },
    show_alert_check_account_usage: false,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

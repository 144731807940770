<template>
  <div id="app">
    <ckeditor
      v-model="dataValue"
      :editor="ClassicEditor"
      :config="editorConfig"
      :disabled="disabled"
    ></ckeditor>
    <div v-show="isFailValidation" class="is-invalid-feedback-message">
      {{ textValidation }}
    </div>
    <div v-if="showNote" style="font-size: 0.8rem">
      {{ $t("mail-template.note_template_body") }}
    </div>
    <slot name="addition-note"></slot>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
// import ThisCustomUploadAdaptorPlugin from "@/plugins/ckeditor/custom_uploader";
import singleSelectionMixin from "@/mixins/single-selection-mixin";
import handleValidateMixin from "@/mixins/handle-validate-mixin";

export default {
  name: "BaseCkeditor",
  components: {
    ckeditor: CKEditor.component,
  },
  mixins: [handleValidateMixin, singleSelectionMixin],
  watch: {
    isFailValidation(value) {
      let ckeditor = this.$el.querySelector(".ck-editor");
      if (value) {
        ckeditor.classList.add("is-invalid");
      } else {
        ckeditor.classList.remove("is-invalid");
      }
    },
  },
};
</script>

<script setup>
import { MyUploadAdaptor } from "@/plugins/ckeditor/custom_uploader";

import { defineProps, defineEmits, computed } from "vue";
import { Font } from "@ckeditor/ckeditor5-font";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import {
  Bold,
  Italic,
  Code,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from "@ckeditor/ckeditor5-basic-styles";
import { Link, LinkImage } from "@ckeditor/ckeditor5-link";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import {
  Table,
  TableToolbar,
  TableColumnResize,
  TableProperties,
  TableCellProperties,
} from "@ckeditor/ckeditor5-table";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { List } from "@ckeditor/ckeditor5-list";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import {
  Image,
  ImageResize,
  ImageResizeEditing,
  ImageResizeHandles,
  ImageStyle,
  ImageInsert,
} from "@ckeditor/ckeditor5-image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
// import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import { SimpleUploadAdapter } from "@ckeditor/ckeditor5-upload";
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { SourceEditing } from "@ckeditor/ckeditor5-source-editing";
import { CodeBlock } from "@ckeditor/ckeditor5-code-block";
import { SpecialCharacters } from "@ckeditor/ckeditor5-special-characters";
import { SpecialCharactersEssentials } from "@ckeditor/ckeditor5-special-characters";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showNote: {
    type: Boolean,
    default: false,
  },
  extraBodyRequest: {
    type: Object,
    default: function () {
      return {};
    },
  },
  usingUploadImage: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["input"]);

const dataValue = computed({
  get() {
    return props.value || "";
  },
  set(newValue) {
    emits("input", newValue);
  },
});

function ThisCustomUploadAdaptorPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdaptor(loader, props.extraBodyRequest);
  };
}

const editorConfig = {
  minHeight: "300px",

  plugins: [
    SpecialCharactersEssentials,
    SpecialCharacters,
    CodeBlock,
    SourceEditing,
    Highlight,
    // ImageUpload,
    SimpleUploadAdapter,
    ImageToolbar,
    ImageCaption,
    Font,
    Essentials,
    Bold,
    Italic,
    Link,
    List,
    Paragraph,
    Heading,
    Code,
    Strikethrough,
    Subscript,
    Superscript,
    Underline,
    Table,
    TableToolbar,
    TableColumnResize,
    TableProperties,
    TableCellProperties,
    Alignment,
    BlockQuote,
    Image,
    ImageResize,
    ImageResizeEditing,
    ImageResizeHandles,
    ImageStyle,
    ImageInsert,
    LinkImage,
  ],

  toolbar: {
    shouldNotGroupWhenFull: true,
    items: [
      "sourceEditing",
      "codeBlock",
      "|",
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "code",
      "subscript",
      "superscript",
      "-",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "alignment:left",
      "alignment:right",
      "alignment:center",
      "alignment:justify",
      "|",
      props.usingUploadImage ? "insertImage" : "",
      "insertTable",
      "|",
      "highlight",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],
  },
  image: {
    toolbar: [
      "resizeImage",
      "|",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageStyle:inline",
      "imageStyle:block",
      "|",
      "toggleImageCaption",
      "|",
      "imageTextAlternative",
      "|",
      "linkImage",
    ],
    insert: {
      type: "auto",
    },
  },
  // simpleUpload: {
  //   // The URL that the images are uploaded to.
  //   uploadUrl: `http://localhost:5000/upload`,

  //   // Enable the XMLHttpRequest.withCredentials property.
  //   withCredentials: true,

  //   // Headers sent along with the XMLHttpRequest to the upload server.
  //   headers: {
  //     "X-CSRF-TOKEN": "CSRF-Token",
  //     Authentication: "Bearer 123123123",
  //   },
  // },
  // extraPlugins: [ThisCustomUploadAdaptorPlugin],
  extraPlugins: [ThisCustomUploadAdaptorPlugin],
};
</script>

<style lang="scss" scoped>
:deep() {
  .ck-editor__editable {
    min-height: 200px;
  }

  .ck-editor {
    &.is-invalid {
      border: solid 1px #dc3545 !important;
    }
  }
}
</style>

import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "access-contents/:content_cms_code/:access_code/:content_code/content-subscribers",
  name: "ContentSubscriberManage",
  component: () =>
    import(
      /* webpackChunkName: "content-subscriber-manage-layout-view" */ "@/views/Manage/ContentSubscriber/LayoutView.vue"
    ),
  redirect: { name: "ContentSubscriberSearch" },
  children: [
    {
      path: "", // /manage/content-subscribers
      name: "ContentSubscriberSearch",
      component: () =>
        import(
          /* webpackChunkName: "content-subscriber-manage-searching-view" */ "@/views/Manage/ContentSubscriber/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/content-subscribers/create
      name: "ContentSubscriberCreate",
      component: () =>
        import(
          /* webpackChunkName: "content-subscriber-manage-create-view" */ "@/views/Manage/ContentSubscriber/Create.vue"
        ),
      props: { default: true },
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:subscriber_id", // /manage/content-subscribers/edit/1
      name: "ContentSubscriberEdit",
      component: () =>
        import(
          /* webpackChunkName: "content-subscriber-manage-edit-view" */ "@/views/Manage/ContentSubscriber/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};

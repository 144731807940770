import memberType from "./member-type";
import accountCmsGroup from "./account-cms-group";
import contentCms from "./content-cms";
import eventCms from "./event-cms";
import accountCmsSetting from "./account-cms-setting";
import cardBuilder from "./card-builder";
import functionalMenu from "./functional-menu";
import storageManage from "./storage-manage";
import paymentMethod from "./payment-method";
import accountLayout from "./account-layout";
import applyCondition from "./apply-condition";
import playmentPlanFeeCondition from "./payment-plan-fee-condition";
import paymentAdjustmentCondition from "./payment-adjustment-condition";
import resourceAccessCondition from "./resource-access-condition";
import appSetting from "./app-setting";
import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "facilities/manage/:facility_id",
  name: "EdicAdminFacilityManagementDetail",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-detail-layout" */ "@/layouts/TheEdicAdminFacilityLayout"
    ),
  meta: {
    middleware: [authAdmin],
  },
  children: [
    {
      path: "profile",
      name: "EdicAdminFacilityProfile",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-profile-layout" */ "@/views/EdicAdmin/Facilities/Manage/Profile/Layout"
        ),
      redirect: { name: "EdicAdminFacilityProfileEdit" },
      children: [
        {
          path: "edit",
          name: "EdicAdminFacilityProfileEdit",
          component: () =>
            import(
              /* webpackChunkName: "edic-admin-facility-management-profile" */ "@/views/EdicAdmin/Facilities/Manage/Profile/Edit"
            ),
          meta: {
            middleware: [authAdmin],
          },
        },
      ],
    },
    memberType,
    accountCmsGroup,
    contentCms,
    eventCms,
    accountCmsSetting,
    cardBuilder,
    functionalMenu,
    storageManage,
    paymentMethod,
    accountLayout,
    applyCondition,
    playmentPlanFeeCondition,
    paymentAdjustmentCondition,
    resourceAccessCondition,
    appSetting,
  ],
};

import axios from "axios";

export default class cloudUploadInstance {
  // eslint-disable-next-line
  constructor(objectData = {}) {
    this.form = new FormData();
  }

  bindUrlReq(url) {
    this.url = url;
    return this;
  }

  bindFields(objectField) {
    if (typeof objectField !== "object")
      throw new Error(`'objectField' should be an object!`);
    for (let propInput in objectField) {
      if (Object.prototype.hasOwnProperty.call(this, propInput)) {
        this[propInput] = objectField[propInput];
        if (this.acceptKeyList.includes(propInput)) {
          this.form.append(propInput, this[propInput]);
        }
      }
    }
    return this;
  }

  bindFile(file) {
    if (file instanceof File) {
      this["content-type"] = file.type;
      this["file"] = file;

      this.form.append("content-type", this["content-type"]);
      this.form.append("file", this["file"]);
    }
    return this;
  }

  getRequest() {
    //
    return axios({
      method: "POST",
      url: this["url"],
      data: this.form,
    });
  }

  getFormData() {
    return this.form;
  }

  data() {
    return this;
  }
}

import {
  GET_VALID_DOMAIN_FACILITY,
  GET_FACILITY_INFO,
  GET_FACILITY_CODE,
  GET_CHECKING_FACILITY_USING_APP,
  GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE,
  GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE,
  GET_FINANCIAL_BALANCE_TYPE,
  GET_FINANCIAL_BALANCE_DATE,
  GET_CLIENT_CAN_REGISTER,
  GET_CHECK_FACILITY_IS_USAGE_ALERT_FLAG,
  GET_CHECK_FACILITY_USAGE_ALERT_MESSAGE,
  GET_SHOW_ALERT_FACILITY_ACCOUNT_USAGE,
} from "./types";
import isEmpty from "lodash/isEmpty";

export default {
  [GET_VALID_DOMAIN_FACILITY]: (state) => state.facility_domain.isValidDomain,
  [GET_FACILITY_INFO]: (state) => {
    let { info } = state.facility_domain;
    return isEmpty(info) ? null : info;
  },
  [GET_FACILITY_CODE]: (state) => {
    if (!isEmpty(state.facility_domain.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.facility_domain.info,
        "code"
      )
        ? state.facility_domain.info.code
        : null;
    }
    return null;
  },
  [GET_CHECKING_FACILITY_USING_APP]: (state) => {
    if (!isEmpty(state.facility_domain.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.facility_domain.info,
        "app_status"
      )
        ? Boolean(state.facility_domain.info?.app_status)
        : false;
    }
    return null;
  },
  [GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE]: (state) => {
    if (!isEmpty(state.facility_domain.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.facility_domain.info,
        "facility_settings"
      )
        ? state.facility_domain.info?.facility_settings[
            "use_organization_structure_flag"
          ] || null
        : null;
    }
    return null;
  },
  [GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE]: (state) => {
    if (!isEmpty(state.facility_domain.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.facility_domain.info,
        "facility_settings"
      )
        ? state.facility_domain.info?.facility_settings[
            "use_corporate_structure_flag"
          ] || null
        : null;
    }
    return null;
  },
  [GET_FINANCIAL_BALANCE_TYPE]: (state) => {
    return (
      state.facility_domain.info?.facility_settings?.financial_balance_type ||
      null
    );
  },
  [GET_FINANCIAL_BALANCE_DATE]: (state) => {
    return (
      state.facility_domain.info?.facility_settings?.financial_balance_date ||
      {}
    );
  },
  [GET_CLIENT_CAN_REGISTER]: (state) => {
    return state.facility_domain.info?.client_can_register || false;
  },
  [GET_CHECK_FACILITY_IS_USAGE_ALERT_FLAG]: (state) => {
    return state.facility_domain.check_account_usage.usage_alert_flag;
  },
  [GET_CHECK_FACILITY_USAGE_ALERT_MESSAGE]: (state) => {
    return state.facility_domain.check_account_usage.usage_alert_message;
  },
  [GET_SHOW_ALERT_FACILITY_ACCOUNT_USAGE]: (state) =>
    state.facility_domain.show_alert_check_account_usage,
};

<template>
  <div ref="element" class="d-block">
    <div class="d-block fixed__on__top__wrapper">
      <div>
        <div class="d-block" :class="{ spacing: !onFixedTop }">
          <account-alert-usage></account-alert-usage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFixedTop",
};
</script>
<script setup>
import AccountAlertUsage from "@/components/Common/AccountAlert/AccountAlertUsage.vue";
import { ref, onMounted } from "vue";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";

// -------- STATE ----------
const onFixedTop = ref(false);
const element = ref(null);
onMounted(() => {
  useIntersectionObserver({
    once: false,
    elementWatcher: element.value,
    callback: () => {
      onFixedTop.value = false;
    },
    callbackOut: () => {
      onFixedTop.value = true;
    },
    option: {
      rootMargin: "100px",
      root: null,
      threshold: 1,
    },
  });
});
</script>

<style lang="scss" scoped>
.fixed__on__top__wrapper {
  transition: all 0.2s ease-in-out;
  margin: 10px 0px;
}
.add__fixed__top {
  position: fixed;
  top: 0;
  margin-right: 15px;
  z-index: 25;
  width: 100%;
}
.spacing {
  padding: 0 15px;
}
</style>

<template>
  <div
    id="menu__container__wrapper"
    class="menu__container__wrapper sticky-on-top"
  >
    <div class="menu__container__wrapper-content__menu">
      <client-menu-item
        v-for="(item, index) in menuHeader"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import { GET_CLIENT_MENU_HEADER } from "@/store/modules/AuthClient/types";
import { mapGetters } from "vuex";
import ClientMenuItem from "./ClientMenuItem.vue";
import debounce from "lodash/debounce";
import useCalculateAlertIncompleteNavbar from "@/hooks/useCalculateCss";

export default {
  name: "ClientMenuWrapper",
  components: { ClientMenuItem },
  computed: {
    ...mapGetters("AuthClient", {
      menuHeader: GET_CLIENT_MENU_HEADER,
    }),
    listMenu() {
      return [
        {
          name: this.$t("menu.label_memeber_code"),
          to: { hash: "#personal-info" },
        },
        {
          name: this.$t("menu.label_announce_from_federation"),
          to: "#",
        },
        {
          name: this.$t("menu.label_entry_completed_tournament"),
          to: "#",
        },
        {
          name: this.$t("menu.label_tournament_results"),
          to: "#",
        },
        {
          name: this.$t("menu.label_menu_training"),
          to: "#",
        },
        {
          name: this.$t("menu.label_menu_collection"),
          to: "#",
        },
        {
          name: this.$t("menu.label_change_profile"),
          to: { name: "ClientChangeProfileView" },
        },
      ];
    },
  },
  setup() {
    const { heightHeaderMenu, getHeightOfElements } =
      useCalculateAlertIncompleteNavbar();
    return {
      heightHeaderMenu,
      getHeightOfElements,
    };
  },
  mounted() {
    const sticky = this.$el.offsetTop;
    // handle for alert
    const alertProfileClass = document.querySelector(
      ".navbar__alert__incomplete__wrapper"
    );
    window.addEventListener(
      "resize",
      debounce(() => {
        this.getHeightOfElements();
        this.resizeAlertBar(alertProfileClass, sticky, this.heightHeaderMenu);
      }, 500)
    );
    window.onscroll = () => {
      this.resizeAlertBar(alertProfileClass, sticky, this.heightHeaderMenu);
    };

    this.$nextTick(() => {
      this.$eventBus.$emit("client-navbar-is-renderred");
    });
  },
  methods: {
    resizeAlertBar(elm, sticky, newPadding) {
      if (elm) {
        if (window.pageYOffset > sticky) {
          elm.style.paddingTop = newPadding + "px";
        } else {
          elm.style.paddingTop = 0;
        }
      }
    },
  },
};
</script>

// import { accountTemp } from '@/constants/commons';
import Store from "@/store";
import {
  ACT_FORCE_FRESH_AUTH,
  ACT_SYNC_CLIENT_AUTH_INFO,
  GET_PROCESS_INFO_IS_DONE,
} from "@/store/modules/AuthClient/types";
// import clientAuthService  from '@/services/client/auth/login';
import Router from "@/router";
import { checkClientManageLifeTime } from "@/helpers/authenticate";

export default async function authClient({ to, next }) {
  const isAuthClient = checkClientManageLifeTime();
  // not authenticated
  if (!isAuthClient) {
    let { path } = to;
    //
    Store.dispatch(`AuthClient/${ACT_FORCE_FRESH_AUTH}`);
    setTimeout(() => {
      Router.push({ name: "ClientJbbfLoginView", query: { redirect: path } });
    }, 500);
  } else {
    // let accountInfo = Store.getters[`AuthClient/${GET_CLIENT_ACCOUNT_INFO}`];
    // authenticate is TRUE
    const isProcessInfoDone =
      Store.getters[`AuthClient/${GET_PROCESS_INFO_IS_DONE}`];
    if (!isProcessInfoDone) {
      try {
        await Store.dispatch(`AuthClient/${ACT_SYNC_CLIENT_AUTH_INFO}`);
      } catch {
        Store.dispatch(`AuthClient/${ACT_FORCE_FRESH_AUTH}`);
        return next({ name: "ClientJbbfLoginView" });
      }
    }

    // try {

    //   let response = await clientAuthService.getInfo();
    //   console.log(response);
    //   localStorage.setItem('client_account_temp', response.temp_flag)
    // } catch (error) {
    //   // console.log(error)
    // }

    if (to.name === "ClientJbbfLoginView") {
      //
      return next({ name: "ClientHomeView" });
    }
    return next();
  }
}

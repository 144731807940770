import auth from "@/middlewares/client/auth";
import guest from "@/middlewares/client/guest";
import payment from "@/middlewares/client/payment";
import { checkClientManageLifeTime } from "@/helpers/authenticate";
import Store from "@/store";
import { ACT_FORCE_FRESH_AUTH } from "@/store/modules/AuthClient/types";

export default [
  {
    path: "/login",
    name: "ClientJbbfLoginView",
    component: () => import("@/views/Client/Jbbf/ClientJbbfLoginView"),
    beforeEnter: (to, from, next) => {
      let isAuthClient = checkClientManageLifeTime();
      if (isAuthClient) {
        return next({ name: "ClientHomeView" });
      } else {
        Store.dispatch(`AuthClient/${ACT_FORCE_FRESH_AUTH}`);
        next();
      }
    },
  },
  {
    path: "/forgot-password",
    name: "ClientJbbfForgotPasswordView",
    component: () =>
      import(
        /* webpackChunkName: "client-forgot-password" */ "@/views/Client/Jbbf/ClientJbbfForgotPasswordView"
      ),
  },
  {
    path: "/reset-password",
    name: "ClientJbbfResetPasswordView",
    component: () =>
      import(
        /* webpackChunkName: "client-reset-password" */ "@/views/Client/Jbbf/ClientJbbfResetPasswordView"
      ),
  },
  {
    path: "/registry-landing",
    name: "ClientPlayerRegistrationView",
    component: () =>
      import("@/views/Client/Jbbf/ClientJbbfPlayerRegistrationView.vue"),
  },
  {
    path: "/confirm-token",
    name: "ClientConfirmTokenView",
    component: () =>
      import("@/views/Client/Jbbf/ClientJbbfConfirmTokenView.vue"),
  },
  {
    path: "",
    component: () =>
      import("@/layouts/Client/Jbbf/ClientJbbfTheDefaultLayout.vue"),
    beforeEnter: (to, from, next) => {
      if (
        to.path == "/" &&
        window.location.host == process.env.VUE_APP_ADMIN_URL &&
        to.name !== "EdicOverView"
      ) {
        next({ name: "EdicOverView" });
      } else {
        if (to.path == "/") {
          next({ name: "ClientHomeView" });
        } else {
          next();
        }
      }
    },
    children: [
      {
        path: "home",
        name: "ClientHomeView",
        component: () => import("@/views/Client/Jbbf/ClientJbbfHomeView.vue"),
        meta: {
          middleware: [guest],
        },
      },
      {
        path: "events/:url/:event_cms_code/",
        redirect: { name: "ClientEventListView" },
        component: () => import("@/views/Client/Jbbf/ClientJbbfEventView.vue"),
        children: [
          {
            path: "",
            name: "ClientEventListView",
            component: () =>
              import("@/views/Client/Jbbf/ClientJbbfEventListView.vue"),
            meta: {
              middleware: [guest],
            },
          },
          {
            path: "entry/:event_code",
            name: "ClientEventEntryView",
            component: () =>
              import("@/views/Client/Jbbf/ClientJbbfEventEntryView.vue"),
            meta: {
              middleware: [guest],
            },
          },
          {
            path: "detail/:event_code",
            name: "ClientEventDetailView",
            component: () =>
              import("@/views/Client/Jbbf/ClientJbbfEventDetailView.vue"),
            meta: {
              middleware: [guest],
            },
          },
        ],
      },

      {
        path: "modal-window-sample",
        name: "ClientModalWindowSampleView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfModalWindowSampleView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "error-input-view",
        name: "ClientErrorInputView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfErrorInputView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "contents/:url/:content_cms_code",
        component: () => import("@/views/Client/Jbbf/Content/Layout.vue"),
        children: [
          {
            path: "",
            name: "ClientContentSearchView",
            component: () => import("@/views/Client/Jbbf/Content/Search.vue"),
            meta: {
              middleware: [guest],
            },
          },
          {
            path: "content-subscribe/:content_code/:access_code",
            name: "ClientContentSubscribeView",
            component: () =>
              import(
                /* webpackChunkName: "client-content-subscribe" */ "@/views/Client/Jbbf/Content/ClientContentSubscribeView.vue"
              ),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "detail/:content_code/:access_code",
            name: "ClientNewsDetailView",
            component: () =>
              import(
                /* webpackChunkName: "client-content-detail" */ "@/views/Client/Jbbf/Content/ClientJbbfContentDetailView.vue"
              ),
            meta: {
              middleware: [guest],
            },
          },
        ],
      },
      {
        path: "category-page",
        component: () =>
          /* webpackChunkName: "client-content-layout" */ import(
            "@/views/Client/Jbbf/Content/Layout.vue"
          ),
        children: [
          {
            path: ":facility_menu_id",
            name: "ClientJbbfEventContentListView",
            component: () =>
              /* webpackChunkName: "client-event-content-list" */
              import("@/views/Client/Jbbf/ClientJbbfEventContentListView.vue"),
            meta: {
              middleware: [guest],
            },
          },
        ],
      },
      {
        path: "new-entry-form",
        name: "ClientNewEntryFormView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfNewEntryFormView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "new-entry-input",
        name: "ClientNewEntryInputView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfNewEntryInputView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "new-entry-error",
        name: "ClientNewEntryErrorView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfNewEntryErrorView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "new-entry-registered",
        name: "NewEntryRegisteredView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfNewEntryRegisteredView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payments/:code/:invoice_code",
        name: "ClientNewEntryConfirmPaymentView",
        component: () =>
          import(
            "@/views/Client/Jbbf/ClientJbbfNewEntryConfirmPaymentView.vue"
          ),
        meta: {
          middleware: [payment],
        },
      },
      {
        path: "category-buttons",
        name: "ClientCategoryButtonView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfCategoryButtonView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "message-after-action",
        name: "ClientMessageAfterActionView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfMessageAfterActionView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "documents",
        name: "ClientDocumentView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfDocumentView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "entry",
        name: "ClientEntryView",
        component: () => import("@/views/Client/Jbbf/ClientJbbfEntryView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/entry-confirmation",
        name: "ClientEntryConfirmView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfEntryConfirmView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/payment-confirmation",
        name: "ClientPaymentConfirmView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfPaymentConfirmView.vue"),
        props: { default: true },
      },
      {
        path: "/register-new-account/:token",
        name: "ClientRegisterNewAccount",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfRegisterNewAccountView.vue"),
      },
      {
        path: "/renew-account/:account_code",
        name: "ClientRenewAccount",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfRenewAccountView.vue"),
      },
      {
        path: "/profile",
        name: "ClientChangeProfileView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfChangeProfileView.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/payment-result",
        name: "ClientResultPaymentView",
        component: () =>
          import("@/views/Client/Jbbf/ClientJbbfPaymentResultView.vue"),
      },
      {
        path: "commercial-laws",
        name: "ClientCommercialLawView",
        component: () =>
          import("@/views/Client/Jbbf/ClientCommercialLawView.vue"),
      },
      {
        path: "policy",
        name: "ClientPolicyView",
        component: () => import("@/views/Client/Jbbf/ClientPolicyView.vue"),
      },
      {
        path: "terms",
        name: "ClientTermView",
        component: () => import("@/views/Client/Jbbf/ClientTermView.vue"),
      },
      {
        path: "/change-email",
        name: "ClientJbbfChangeEmailView",
        component: () =>
          import(
            /* webpackChunkName: "client-change-email" */ "@/views/Client/Jbbf/ClientJbbfChangeEmailView"
          ),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/reset-email",
        name: "ClientJbbfResetEmailView",
        component: () =>
          import(
            /* webpackChunkName: "client-reset-email" */ "@/views/Client/Jbbf/ClientJbbfResetEmailView"
          ),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/period-registry-end",
        name: "PeriodRegistryEnd",
        component: () =>
          import(
            /* webpackChunkName: "client-new-registry-end" */ "@/views/Client/Jbbf/ClientJbbfNewRegistryEnd.vue"
          ),
      },
      {
        path: "my-invoices",
        name: "ClientMyInvoiceView",
        component: () =>
          import(
            /* webpackChunkName: "client-jbbf-my-invoice" */ "@/views/Client/Jbbf/ClientJbbfMyInvoiceView.vue"
          ),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/corporate/:corporate_code/register-new-account-member",
        name: "CorporateRegisterNewAccountMember",
        component: () =>
          import(
            /* webpackChunkName: "client-corporate-register-new-account" */ "@/views/Client/Jbbf/ClientCorporateRegisterNewAccountMember"
          ),
      },
      {
        path: "/corporate/account-register-complete",
        name: "CorporateRegisterComplete",
        component: () =>
          import(
            /* webpackChunkName: "client-corporate-register-complete" */ "@/views/Client/Jbbf/ClientCorporateRegisterComplete"
          ),
      },
    ],
  },
  {
    path: "/invoices/:account_code/:invoice_code",
    name: "InvoiceDownloadPdf",
    component: () =>
      import("@/views/Client/Jbbf/ClientInvoiceDownloadPdfView.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/unauthorized",
    name: "ClientPageUnauthorized",
    component: () => import("@/views/Errors/401.vue"),
  },
  {
    path: "*",
    name: "ClientPageNotFoundView",
    component: () => import("@/views/Client/Jbbf/Errors/404.vue"),
  },
];

// const router = new VueRouter({
// 	mode: 'history',
// 	base: process.env.BASE_URL,
// 	routes,
//   scrollBehavior() {
//     return { x: 0, y: 0 };
//   }
// })

// router.beforeEach((to, from ,next) => {
//   eventBus.$emit('startLoad');
//   next();
// });

// router.beforeResolve((to, from, next) => {
//   // if (to.name) {
//   //   NProgress.start()
//   //   console.log('start')
//   // }

//   setTimeout(() => {
//     eventBus.$emit('stopLoad')
//   }, 500)

//   next();
// });

// // router.afterEach(() => {
// //   NProgress.done();
// //   console.log('done')
// // })

// export default router

<template>
  <div
    class="permission__menu__wrappper"
    :class="{
      'active-parent ': showChildren && isParent,
      'active-children': showChildren && !isParent,
    }"
  >
    <div
      v-if="children && children.length"
      class="normal__parent__link"
      @click="toggleChildren"
    >
      <div class="content__parent__link">
        <div v-if="!noneIcon" class="icon__display">
          <icon
            v-if="useEmojiIcon"
            :icon="realIconName"
            height="24"
            :color="iconColor"
          />
          <template v-if="useImgIcon">
            <img :src="iconActivePath || iconInactivePath" />
          </template>
        </div>
        <router-link :to="url" custom v-slot="{ navigate, href }">
          <a
            :href="href"
            class="parent__name"
            :style="{ 'margin-left': indent }"
            @click.stop="navigate"
            >{{ name }}</a
          >
        </router-link>
      </div>

      <i
        v-show="children && children.length"
        class="fa-solid fa-caret-down icon__dropdown__menu inactive"
      ></i>
    </div>

    <!-- sidebarlink children -->
    <sidebar-link
      v-if="!children || !children.length"
      :style="{ 'margin-left': indent }"
      :to="url"
      :manage="true"
    >
      <template #default="{ isActive }">
        <div v-if="!noneIcon" class="icon__display">
          <icon
            v-if="useEmojiIcon"
            :icon="realIconName"
            height="24"
            :color="iconColor"
          />
          <template v-if="useImgIcon">
            <img :src="isActive ? iconActivePath : iconInactivePath" />
          </template>
        </div>
        <p>{{ name }}</p>
      </template>
    </sidebar-link>

    <div
      ref="PermissionMenuChildren"
      class="permission__menu__children"
      :class="{ active: showChildren }"
    >
      <div class="d-block here">
        <permission-menu
          v-for="menu in children"
          :key="menu.id"
          :children="menu.children"
          :name="menu.name"
          :depth="depth + 1"
          :is-parent="false"
          :icon="menu.icon"
          :url="menu.url || ''"
          :icon-type="menu.icon_type"
          :icon-color="menu.icon_color"
          :icon-prefix="menu.icon_prefix"
          :icon-name="menu.icon_name"
          :icon-active-path="menu.customize_icon_active_image_path"
          :icon-inactive-path="menu.customize_icon_inactive_image_path"
          @open-children="handleShowchildren"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { iconType } from "@/constants/commons";

export default {
  name: "PermissionMenu",
  components: {
    Icon,
  },
  props: {
    children: {
      type: Array,
      default: function () {
        return undefined;
      },
    },
    name: String,
    depth: [Number, String],
    isParent: Boolean,
    icon: String,
    url: String,
    iconType: [String, Number],
    iconColor: [String],
    iconPrefix: String,
    iconName: String,
    iconActivePath: {
      type: String,
      default: null,
    },
    iconInactivePath: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showChildren: false,
    };
  },
  computed: {
    indent() {
      return `${this.depth * 16}px`;
    },
    realIconName() {
      return `${this.iconPrefix}:${this.iconName}`;
    },
    useEmojiIcon() {
      return this.iconType == iconType.emoji.value;
    },
    useImgIcon() {
      return this.iconType == iconType.image.value;
    },
    noneIcon() {
      return this.iconType == iconType.none.value;
    },
  },
  watch: {
    showChildren() {
      if (this.showChildren) {
        this.$el.querySelector(".icon__dropdown__menu").classList.add("active");
        this.$el
          .querySelector(".icon__dropdown__menu")
          .classList.remove("inactive");

        this.$refs.PermissionMenuChildren.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        this.$el
          .querySelector(".icon__dropdown__menu")
          .classList.add("inactive");
        this.$el
          .querySelector(".icon__dropdown__menu")
          .classList.remove("active");
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initOpenChildren();
    });
  },

  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },

    initOpenChildren() {
      const fullPath = this.$route.fullPath;
      if (!this.children || !this.children.length) {
        if (fullPath.includes(this.to)) {
          this.$emit("open-children");
        }
      }
    },

    handleShowchildren() {
      this.showChildren = true;
      this.$emit("open-children", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.permission__menu__wrappper {
  transition: all 0.2s ease-in-out;
  background-color: inherit;
  &.active-parent {
    background-color: rgba(54, 54, 54, 0.5);
    padding: 5px 0;
  }

  &.active-chidlrent {
    padding: 5px 0;
  }
}
.normal__parent__link {
  color: #ffffff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 6px 15px;
  justify-content: space-between;
  cursor: pointer;
  gap: 5px;
  a {
    color: #ffffff;
  }

  .content__parent__link {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 3px;
    .icon__display {
      width: 30px;
      margin-right: 15px;
      text-align: center;
      float: left;
      box-sizing: border-box;
      img {
        width: 95%;
        height: 95%;
        object-fit: cover;
      }
    }
    p {
      margin: 0;
      line-height: 30px;
      font-size: 12px;
      font-weight: 450;
      text-transform: none;
      display: inline-flex;
      letter-spacing: 0.5px;
      word-break: break-all;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.13);
    opacity: 1;
  }

  .parent__name {
    margin: 0;
    line-height: 30px;
    font-size: 12px;
    font-weight: 450;
    text-transform: none;
    display: inline-flex;
    letter-spacing: 0.5px;
  }

  .icon__dropdown__menu {
    transition: all 0.5s ease-in-out;

    &.active {
      animation-name: rotationUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    &.inactive {
      animation-name: rotationDown;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }
}

.permission__menu__children {
  height: 0;
  overflow: hidden;
  transition: height 0.7s ease-in-out;
  transition: opacity 0.2s ease-in;
  opacity: 0;
  &.active {
    height: auto;
    opacity: 1;
    display: block;
  }
}

@keyframes rotationUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes showDown {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}

@keyframes rotationDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>

import isEmpty from "lodash/isEmpty";
import cloudUploadInstance from "./cloudUploadInstance";

export default class gcsUploadInstance extends cloudUploadInstance {
  constructor(objectData = {}) {
    super();

    this["url"] = "";
    this["key"] = "";
    this["acl"] = "";
    this["x-goog-algorithm"] = "";
    this["x-goog-credential"] = "";
    this["x-goog-signature"] = "";
    this["x-goog-date"] = "";
    this["policy"] = "";
    this["content-type"];
    this["file"];

    this.acceptKeyList = [
      "key",
      "acl",
      "x-goog-algorithm",
      "x-goog-credential",
      "x-goog-signature",
      "x-goog-date",
      "policy",
    ];

    if (!isEmpty(objectData)) {
      this.bindFields(objectData);
    }
  }
}

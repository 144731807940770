import CommonTabWrapper from "@/components/Common/CommonTab/TabsWrapper.vue";
import CommonTabItem from "@/components/Common/CommonTab/TabItem.vue";

// load all file base components
const componentFiles = require.context(
  "@/components/Bases",
  true,
  /[A-Za-z]+\.vue$/
);

const GlobalComponents = {
  install(Vue) {
    componentFiles.keys().forEach((elementFile) => {
      // const moduleName = elementFile.replace(/(\.\/|\.vue)/g, '');
      const moduleComponent = componentFiles(elementFile).default;
      Vue.component(moduleComponent.name, moduleComponent);
    });

    // Register global Component
    Vue.component("CommonTabWrapper", CommonTabWrapper);
    Vue.component("CommonTabItem", CommonTabItem);
  },
};

export default GlobalComponents;

<template>
  <div class="alert__account__usage__wrapper">
    <div class="d-flex justify-content-center align-items-center px-2">
      <Icon icon="ci:circle-warning" height="26" color="#b08b3d" />
    </div>
    <div class="d-flex w-100 flex-column">
      <h5 class="d-block alert__title m-0 p-0">警告</h5>
      <div class="alert__content">
        {{ $facilityUsageAlertMessage }}
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center px-2">
      <div @click="closeAlert">
        <Icon
          icon="ic:outline-close"
          height="24"
          color="#b08b3d"
          class="cursor-pointer icon__close"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue2";
import Store from "@/store";
import { SET_SHOW_ALERT_ACCOUNT_USAGE } from "@/store/modules/Facility/types";

// ------- methods --------
const closeAlert = () => {
  Store.commit(`Facility/${SET_SHOW_ALERT_ACCOUNT_USAGE}`, false);
};
</script>

<style lang="scss" scoped>
.alert__account__usage__wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr min-content;
  gap: 10px;
  background-color: #ffefbc;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #dbbc61;

  .alert__title {
    color: #ac8229;
    font-weight: bold;
    font-size: 18px;
  }

  .alert__content {
    color: #af8f4a;
    font-weight: bold;
  }

  .icon__close {
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #b08b3d;
    }
  }
}
</style>

<template>
  <div ref="baseDisplayCkeditorContent" class="ck-content">
    <div class="d-block" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "BaseDisplayCkeditorContent",
  props: {
    content: {
      type: String,
      default: "",
    },
  },

  watch: {
    content: {
      handler: function (value) {
        if (value) {
          this.coverTableDisplay();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.coverTableDisplay();
  },
  updated() {
    this.coverTableDisplay();
  },

  methods: {
    coverTableDisplay() {
      let tables = document.querySelectorAll("figure.table");
      if (tables.length) {
        tables.forEach((element) => {
          element.style.display = "block";
          element.style.overflow = "auto";
        });
      }
    },
  },
};
</script>

import { reactive } from "vue";
import { io } from "socket.io-client";
import Store from "@/store";
import { SET_ERROR_FOR_ALL_PROCESSING_EXPORT } from "@/store/modules/ExportProgressing/types";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
  socketId: null,
  intervalConnect: null,
});

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.VUE_APP_BFF_NODE_HTTP || "https://bff-new-edic.asto-system.asia";

export const socket = io(URL, {
  autoConnect: false,
  reconnection: false,
  transports: ["websocket"],
});

export const reconnnectSocket = () => {
  state.intervalConnect = setInterval(() => {
    console.log("try to reconnect");
    startConnect();
  }, 1000);
};

export const startConnect = () => {
  socket.connect();
};

socket.on("connect", () => {
  console.log(socket.id);
  state.socketId = socket.id;
  state.connected = true;
  if (state.intervalConnect) {
    clearInterval(state.intervalConnect);
    state.intervalConnect = null;
  }
});

socket.on("disconnect", () => {
  console.log("disconnect to socket");
  state.socketId = null;
  // set all fail for processing exporting csv;
  Store.commit(`ExportProgressing/${SET_ERROR_FOR_ALL_PROCESSING_EXPORT}`);
  state.connected = false;
});

socket.on("foo", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar", (...args) => {
  state.barEvents.push(args);
});

import gcsUploadInstance from "./gcsUploadInstance";
import s3UploadInstance from "./s3UploadInstance";
import { uploadTypeService } from "@/constants/uploadTypeService";
// const array = [
//   {
//     url: '',
//     fields: {},
//     public_url: '',
//     url: '',
//     version_url: '',
//     cloud_storage_service: 'gcs',
//     file: 'FILE'
//   }
// ]
export default class UploadStorageFile {
  constructor(objectInput = {}) {
    if (objectInput instanceof Object == false)
      throw new Error(`UploadStorageFile constructor expects object input`);

    this.url = "";
    this.fields = "";
    this.public_url = "";
    this.version_url = "";
    this.cloud_storage_service = "";
    this.file = null;

    if (Object.keys(objectInput).length) {
      this.bindObject(objectInput);
    }
  }

  bindObject(objectInput) {
    if (typeof objectInput !== "object")
      throw new Error(`'objectInput' should be an object!`);
    for (let propInput in objectInput) {
      if (Object.prototype.hasOwnProperty.call(this, propInput)) {
        this[propInput] = objectInput[propInput];
      }
    }
    return this;
  }

  prepareUpload() {
    switch (this.cloud_storage_service) {
      case uploadTypeService.gcs: {
        const gcsInstance = new gcsUploadInstance({
          url: this.url,
          ...this.fields,
        });

        // bind file
        gcsInstance.bindFile(this.file);
        return gcsInstance.getRequest();
      }

      case uploadTypeService.s3: {
        const s3Instance = new s3UploadInstance({
          url: this.url,
          ...this.fields,
        });

        // bind file
        s3Instance.bindFile(this.file);
        return s3Instance.getRequest();
      }

      default:
        throw "'uploadFileInstance' only support 'gcs | s3' upload type!";
    }
  }
}

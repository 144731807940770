// actions
export const ACT_CHECK_VALID_DOMAIN_FACILITY =
  "ACT_CHECK_VALID_DOMAIN_FACILITY";
export const ACT_SET_THE_CHECK_ACCOUNT_USAGE =
  "ACT_SET_THE_CHECK_ACCOUNT_USAGE";
export const ACT_GET_FACILITY_ALERT_ACOUNT_USAGE =
  "ACT_GET_FACILITY_ALERT_ACOUNT_USAGE";

// mutations
export const SET_VALID_URL_FACILITY = "SET_VALID_URL_FACILITY";
export const SET_FACILITY_DOMAIN_INFO = "SET_FACILITY_DOMAIN_INFO";
export const SET_CONFIG = "SET_CONFIG";
export const SET_CLIENT_CAN_REGISTER = "SET_CLIENT_CAN_REGISTER";
export const SET_SHOW_ALERT_ACCOUNT_USAGE = "SET_OFF_SHOW_ALERT_ACCOUNT_USAGE";

// getters
export const GET_FACILITY_DOMAIN_ACCESS = "GET_FACILITY_DOMAIN_ACCESS";
export const GET_FACILITY_INFO = "GET_FACILITY_INFO";
export const GET_VALID_DOMAIN_FACILITY = "GET_VALID_DOMAIN_FACILITY";
export const GET_FACILITY_CODE = "GET_FACILITY_CODE";
export const GET_CHECKING_FACILITY_USING_APP =
  "GET_CHECKING_FACILITY_USING_APP";
export const GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE =
  "GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE";
export const GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE =
  "GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE";
export const GET_FINANCIAL_BALANCE_TYPE = "GET_FINANCIAL_BALANCE_TYPE";
export const GET_FINANCIAL_BALANCE_DATE = "GET_FINANCIAL_BALANCE_DATE";
export const GET_CLIENT_CAN_REGISTER = "GET_CLIENT_CAN_REGISTER";
export const GET_CHECK_FACILITY_IS_USAGE_ALERT_FLAG =
  "GET_CHECK_FACILITY_IS_USAGE_ALERT_FLAG";
export const GET_CHECK_FACILITY_USAGE_ALERT_MESSAGE =
  "GET_CHECK_FACILITY_USAGE_ALERT_MESSAGE";
export const GET_SHOW_ALERT_FACILITY_ACCOUNT_USAGE =
  "GET_SHOW_ALERT_FACILITY_ACCOUNT_USAGE";

import isEmpty from "lodash/isEmpty";
import cloudUploadInstance from "./cloudUploadInstance";

export default class s3UploadInstance extends cloudUploadInstance {
  constructor(objectData = {}) {
    super();

    this["url"] = "";
    this["key"] = "";
    this["acl"] = "";
    this["X-Amz-Credential"] = "";
    this["X-Amz-Algorithm"] = "";
    this["X-Amz-Date"] = "";
    this["Policy"] = "";
    this["X-Amz-Signature"] = "";
    this["file"];

    this.acceptKeyList = [
      "key",
      "acl",
      "X-Amz-Credential",
      "X-Amz-Algorithm",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature",
    ];

    if (!isEmpty(objectData)) {
      this.bindFields(objectData);
    }
  }
}

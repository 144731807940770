<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu v-if="false" slot="content"></mobile-menu>

      <!-- <permission-menu
        v-for="menu in permission_tree_menu"
        :key="menu.id"
        :children="menu.children"
        :name="menu.name"
        :depth="0"
        :isParent="true"
        :icon="menu.icon"
        :to="menu.to"
      /> -->

      <!-- manage -->
      <template v-if="managePage">
        <li>
          <div class="nav-link-search-menu">
            <div class="search-icon">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <input
              v-model="searchPermissionMenuTree"
              class="search__menu__item"
              type="text"
            />
          </div>
        </li>
        <permission-menu
          v-for="menu in filterPermissionMenuTree"
          :key="menu.id"
          :children="menu.children"
          :name="menu.name"
          :depth="0"
          :is-parent="true"
          :icon="menu.icon"
          :url="menu.url || ''"
          :icon-type="menu.icon_type"
          :icon-color="menu.icon_color"
          :icon-prefix="menu.icon_prefix"
          :icon-name="menu.icon_name"
          :icon-active-path="menu.customize_icon_active_image_path"
          :icon-inactive-path="menu.customize_icon_inactive_image_path"
        />
        <!-- <template v-for="(itemMenu, index) in facilityAccessMenu">
          <sidebar-link 
            :to="itemMenu.to"
            :key="index"
            :manage="true"
            :type="itemMenu.type"
          >
            <i :class="itemMenu.icon"></i>
            <p>{{ itemMenu.title}}</p>
          </sidebar-link>
        </template> -->
      </template>

      <!-- admin -->
      <template v-if="adminPage">
        <sidebar-link
          v-for="(itemMenu, index) in adminAccessMenu"
          :key="index"
          :to="itemMenu.to"
        >
          <i :class="itemMenu.icon"></i>
          <p>{{ itemMenu.title }}</p>
        </sidebar-link>
      </template>

      <!--<sidebar-link to="/admin/typography">
        <i class="nc-icon nc-paper-2"></i>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/admin/icons">
        <i class="nc-icon nc-atom"></i>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/admin/notifications">
        <i class="nc-icon nc-bell-55"></i>
        <p>Notifications</p>
      </sidebar-link> -->

      <template slot="bottom-links">
        <li class="active" router-link-exact-active="">
          <a href="#" class="nav-link" @click="logout">
            <i class="nc-icon nc-button-power"></i>
            <p>{{ $t("panel.logout") }}</p>
          </a>
        </li>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <the-fixed-top
        v-if="$facilityIsUsageAlertFlag && $facilityShowAlertAccountUsage"
        class="my-1"
      />
      <dashboard-content @click="toggleSidebar"> </dashboard-content>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TheTopNavbar.vue";
import DashboardContent from "./TheContent.vue";
import MobileMenu from "./MobileMenu.vue";
import { adminMenu } from "@/constants/menu";
import {
  ACT_LOGOUT,
  GET_IS_SCREEN_MANAGE,
  GET_PERMISSION_MENU,
  GET_ACCOUNT_IS_AUTH,
  GET_PERMISSION_TREE_MENU,
} from "@/store/modules/AuthManage/types";
import {
  ACT_LOGOUT as ADMIN_ACT_LOGOUT,
  GET_ACCOUNT_IS_AUTH as ADMIN_GET_ACCOUNT_IS_AUTH,
} from "@/store/modules/AuthAdmin/types";
import { ACT_SET_REFRESH_DEFAULT } from "@/store/modules/Organization/types";
import { mapGetters } from "vuex";
import { menuManageType } from "@/constants/commons";
import PermissionMenu from "./PermissionMenu.vue";
import _ from "lodash";
import { socket, state, reconnnectSocket } from "@/socket";
import TheFixedTop from "./TheFixedTop.vue";

export default {
  name: "TheDashboardLayout",
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu,
    PermissionMenu,
    TheFixedTop,
  },
  data() {
    return {
      adminMenu,
      menuManageType,
      searchPermissionMenuTree: "",
    };
  },
  computed: {
    socketStateConnect() {
      return state.connected;
    },
    managePage() {
      const path = this.$route.path;
      return /^\/manage\//.test(path);
    },

    adminPage() {
      const path = this.$route.path;
      return /^\/admin\//.test(path);
    },

    facilityAccessMenu() {
      return this.isAuthManage ? this.permission_menu : [];
    },

    adminAccessMenu() {
      return adminMenu;
    },

    // sidebarMenu() {
    //   if (this.isAuthManage && this.isManage) {
    //     return this.permission_menu
    //   } else {
    //     return adminMenu
    //   }
    // },

    ...mapGetters("AuthManage", {
      permission_menu: GET_PERMISSION_MENU,
      isAuthManage: GET_ACCOUNT_IS_AUTH,
      isManage: GET_IS_SCREEN_MANAGE,
      permission_tree_menu: GET_PERMISSION_TREE_MENU,
    }),

    ...mapGetters("AuthAdmin", {
      isAuthAdmin: ADMIN_GET_ACCOUNT_IS_AUTH,
    }),

    filterPermissionMenuTree() {
      const original = _.cloneDeep(this.permission_tree_menu);
      if (!this.searchPermissionMenuTree) return original;
      const keySearch = this.searchPermissionMenuTree;
      return original.filter(function f(o) {
        if (
          o.name
            .toString()
            .toLowerCase()
            .includes(keySearch.toString().toLowerCase())
        )
          return true;
        if (o.children) {
          return (o.children = o.children.filter(f)).length;
        }
      });
    },
  },
  watch: {
    socketStateConnect: {
      immediate: true,
      handler() {
        if (!this.socketStateConnect) {
          reconnnectSocket();
        }
      },
    },
    isAuthManage() {
      if (!this.isAuthManage && this.isManage) {
        this.$store.dispatch(`Organization/${ACT_SET_REFRESH_DEFAULT}`);
        this.$router.push({ name: "ManageLogin" }); // sync tabs action
      }
    },
    isAuthAdmin() {
      if (!this.isAuthAdmin) {
        this.$router.push({ name: "AdminLogin" });
      }
    },
  },
  // async mounted() {
  //   socket.open();
  //   // this.$socket.emit("join", "channel_private_notification");
  //   // this.sockets.subscribe("MY_EVENT_NOTFI", (data) => {
  //   //   console.log("Channel name: MY_EVENT_NOTFI", data);
  //   // });
  // },
  destroyed() {
    // this.$socket.emit("leave", "channel_private_notification");
    socket.disconnect();
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
      // this.$store
      if (this.adminPage) {
        this.$store.dispatch(`AuthAdmin/${ADMIN_ACT_LOGOUT}`);
      } else {
        this.$store.dispatch(`AuthManage/${ACT_LOGOUT}`);
      }
    },
  },
};
</script>
<style lang="scss"></style>
